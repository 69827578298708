<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="formID === undefined"
    >
      <h4 class="alert-heading">
        Error fetching form data
      </h4>
      <div class="alert-body">
        No form found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'admin-form-folders'}"
        >
          Forms List
        </b-link>
        for other forms.
      </div>
    </b-alert>

    <FormBuilder
      v-if="formID"
      :type="'form'"
      :form-id="formID"
    />
  </div>
</template>

<script>
import {
  BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import FormBuilder from '@/views/components/form-builder/FormBuilder.vue'

export default {
  components: {
    BAlert,
    BLink,
    FormBuilder,
  },
  data() {
    return {
      formID: router.currentRoute.params.id,
    }
  },
  methods: {},
}
</script>
