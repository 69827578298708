import filePickerCallback from '@/helpers/filePickerCallback'

export const tinyOptions = {
  data() {
    return {
      tinyAPI: process.env.VUE_APP_TINYMCE,
      tinyOptions: {
        height: 500,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen pagebreak',
          'insertdatetime media table paste code help wordcount',
        ],
        toolbar:
          // eslint-disable-next-line no-multi-str
          'undo redo | formatselect | bold italic backcolor | pagebreak | \
          alignleft aligncenter alignright alignjustify | \
          bullist numlist outdent indent | code | removeformat | image | help',
        pagebreak_separator: '<pagebreak>',
        file_picker_types: 'image',
        // eslint-disable-next-line no-unused-vars
        file_picker_callback: filePickerCallback,
      },
    }
  },
}

export const _ = null
