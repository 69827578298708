/* eslint-disable func-names */
import axios from '@axios'

const uploadFiles = (ID, file) => axios
  .post(`/auth/file/upload/${ID}`, file, { headers: { 'Content-Type': 'multipart/form-data' } })
  .then(response => response.data)
  .catch(error => {
    const { response } = error
    return response.status
  })

const filePickerCallback = cb => {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')

  input.onchange = function () {
    const file = this.files[0]

    const reader = new FileReader()
    reader.onload = function () {
      const formData = new FormData()
      formData.append('file', file)
      uploadFiles(`files-${new Date().getTime()}`, formData).then(response => {
        const imageUri = response.file_url
        cb(`${imageUri}`, { title: file.name })
      })
    }
    reader.readAsDataURL(file)
  }

  input.click()
}

export default filePickerCallback
