<template>
  <div>
    <div
      v-for="(array, arrayIndex) in scopedConditions"
      :key="arrayIndex"
      class="mb-1"
    >
      <div>
        <span class="modal-if-else">{{ arrayIndex == 0 ? 'If' : 'Or If' }}</span>
      </div>
      <ConditionRow
        v-for="(condition, itemIndex) in array"
        :key="itemIndex"
        :condition="condition"
        :condition-index="itemIndex"
        :condition-block-index="arrayIndex"
        :condition-block-length="array.length"
        :fields-data="fieldsData"
        @removeConditionRow="removeConditionRow(itemIndex, arrayIndex)"
      />
      <b-button
        class="mr-1"
        variant="outline-primary"
        size="sm"
        @click="addAndCondition(arrayIndex)"
      >
        + And
      </b-button>
      <b-button
        v-if="arrayIndex == scopedConditions.length - 1"
        variant="outline-primary"
        size="sm"
        @click="addOrCondition"
      >
        + Or
      </b-button>
    </div>
  </div>

</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import ConditionRow from '@/views/components/form-builder/modals/components/ConditionRow.vue'

export default {
  name: 'ScopedConditions',
  components: {
    BButton,
    ConditionRow,
  },
  props: {
    fieldsData: {
      type: Array,
      default: () => {},
    },
    scopedConditions: {
      type: Array,
      default: () => [],
    },
    conditionId: {
      type: String,
      required: true,
    },
  },
  methods: {
    addAndCondition(arrayIndex) {
      this.scopedConditions[arrayIndex].push({
        field_id: null,
        operator: '',
        value: '',
      })
    },
    addOrCondition() {
      this.scopedConditions.push(
        [
          {
            field_id: null,
            operator: '',
            value: '',
          },
        ],
      )
    },
    removeConditionRow(fieldIndex, arrayIndex) {
      if (arrayIndex > 0 && this.scopedConditions[arrayIndex].length === 1) {
        this.scopedConditions.splice(arrayIndex, 1)
      } else {
        this.scopedConditions[arrayIndex].splice(fieldIndex, 1)
      }
    },
  },
}
</script>

<style scoped>
.modal-if-else{
  margin-bottom: 10px;
  color: #7366F0;
  font-weight: bold;
  font-size: 14px;
}
</style>
