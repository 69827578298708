<template>

  <b-card
    class="mb-1"
  >
    <h4 class="d-flex justify-content-between align-items-center">
      Condition {{ conditionIndex + 1 }}
      <SquareIcon
        size="19"
        icon="Trash2Icon"
        class="cursor-pointer"
        @onIconClick="removeItem(conditionsData.id)"
      />
    </h4>
    <scoped-conditions
      :fields-data="fieldsData"
      :scoped-conditions="conditionsData.scoped_conditions"
      :condition-id="conditionsData.id"
    />

    <div>
      <div class="d-flex justify-content-between">
        <span class="modal-if-else">Then</span>
      </div>

      <!-- Second Row -->
      <b-row>
        <b-col
          cols="4"
        >
          <b-form-group
            label="Action"
          >
            <validation-provider
              #default="{ errors }"
              name="Action"
              rules="required"
              :vid="`Action${conditionIndex}`"
            >
              <v-select
                v-model="conditionsData.action"
                :options="actionOptions"
                label="text"
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="option => option.value"
                placeholder="Select an action"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="stepsOptions.length"
          cols="4"
        >
          <b-form-group
            label="Apply To"
          >
            <validation-provider
              #default="{ errors }"
              name="Apply to"
              rules="required"
              :vid="`Apply to${conditionIndex}`"
            >
              <v-select
                v-model="conditionsData.related_type"
                :options="applyTypes"
                :disabled="isDisabledSelection"
                label="text"
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="option => option.value"
                placeholder="Select an option"
                @input="conditionsData.related_id = null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="conditionsData.related_type === 'Field'"
          cols="4"
        >
          <b-form-group
            label="Field Key"
          >
            <validation-provider
              #default="{ errors }"
              name="Field Key"
              rules="required"
              :vid="`Field Key${conditionIndex}`"
            >
              <v-select
                v-model="conditionsData.related_id"
                :options="childrenOptions"
                label="text"
                :clearable="false"
                :disabled="isDisabledSelection"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="option => option.value"
                placeholder="Select a Field"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col
          v-if="conditionsData.related_type === 'Step'"
          cols="4"
        >
          <b-form-group
            label="Step Key"
          >
            <validation-provider
              #default="{ errors }"
              name="Step Key"
              rules="required"
              :vid="`Step Key${conditionIndex}`"
            >
              <!-- :disabled="!conditionsData.parent_field" -->
              <v-select
                v-model="conditionsData.related_id"
                :options="stepsOptions"
                label="text"
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="option => option.value"
                placeholder="Select a step"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-card>

</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import {
  BCard, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import SquareIcon from '@/views/ui/icon/SquareIcon.vue'
import ScopedConditions from '@/views/components/form-builder/modals/components/ScopedConditions.vue'

export default {
  name: 'AddItemModal',
  components: {
    SquareIcon,
    BCard,
    BFormGroup,
    BCol,
    BRow,
    ValidationProvider,
    vSelect,
    ScopedConditions,
  },
  props: {
    fieldsData: {
      type: Array,
      default: () => {},
    },
    conditionsData: {
      type: Object,
      default: () => {},
    },
    stepsOptions: {
      type: Array,
      default: () => [],
    },
    conditionIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      applyTypes: [
        { value: 'Field', text: 'Field' },
        { value: 'Step', text: 'Step' },
      ],
      actionOptions: [
        { value: 'Show', text: 'Show' },
        { value: 'Hide', text: 'Hide' },
      ],
      required,
    }
  },
  computed: {
    childrenOptions() {
      return this.fieldsData.filter(item => {
        const selectedConditionFields = this.conditionsData.scoped_conditions
          .flat(Infinity)
          .map(item => item.field_id)
        return !selectedConditionFields.includes(item.value)
      })
    },
    isDisabledSelection() {
      return this.conditionsData?.scoped_conditions
        .flat(Infinity)
        .map(item => item.field_id)
        .some(item => item === null)
    },
  },
  methods: {
    removeItem(id) {
      this.$emit('removeConditionsRow', id)
    },
  },
}
</script>

<style scoped>
.modal-if-else{
  margin-bottom: 10px;
  color: #7366F0;
  font-weight: bold;
  font-size: 14px;
}
</style>
