export const formConditionsMethods = {

  methods: {
    setFieldsForConditions(val) {
      return val
        .filter(item => ![null].includes(item.type))
        .reduce((arr, item) => {
          arr.push({
            value: item.id,
            text: item.name,
            type: item.type,
            meta: item.options,
          })
          return arr
        }, [])
    },
    setFormConditions(conditions) {
      this.conditions = conditions

      conditions.forEach(condition => {
        const relatedFieldId = condition.related_id
        const selectedConditionFields = condition.scoped_conditions
          .flat(Infinity)
          .map(item => item.field_id)
        this.fieldsList.forEach(field => {
          if (selectedConditionFields.includes(field.id) || field.id === relatedFieldId) {
            // eslint-disable-next-line no-param-reassign
            field.isCondition = true
          }
          if (field.id === relatedFieldId && condition.action === 'Show') {
            // eslint-disable-next-line no-param-reassign
            field.isVisibility = true
          }
        })
      })
    },
    removeCondition(option) {
      this.fieldsList.forEach(item => {
        item.conditions = item.conditions.filter(el => el.parent_field !== option.id || (el.parent_field === option.id && el.value !== option.val)) // eslint-disable-line no-param-reassign
      })
    },
  },
}

export const _ = null
