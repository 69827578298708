<template>
  <div
    :style="getSquareSize"
    class="square"
    @click="$emit('onIconClick')"
  >
    <feather-icon
      :icon="icon"
      :size="size"
    />
  </div>

</template>

<script>
export default {
  name: 'SquareIcon',
  props: {
    icon: {
      type: String,
      default: 'null',
    },
    size: {
      type: String,
      default: '25',
    },
    squareSize: {
      type: String,
      default: '38',
    },
  },
  computed: {
    getSquareSize() {
      return {
        height: `${this.squareSize}px`,
        width: `${this.squareSize}px`,
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.square {
  border-radius: 5px;
  border: 1px solid #7366F0;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #7366F0;
  }
}
</style>
