<template>

  <b-modal
    :id="'move-before-field-' + type"
    centered
    title="Move Before {field}"
    ok-title="Apply"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    size="md"
    class="p-0"
    @ok="apply"
    @hidden="resetModal"
  >
    <b-form-group
      :label="`Move the '${fieldToMove.label}' field before the field:`"
      class="mt-1"
    >
      <v-select
        v-model="fieldToSelect"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        :options="fieldsOptions"
        :placeholder="'Select Field'"
      />
    </b-form-group>
  </b-modal>

</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BFormGroup,

    vSelect,
  },
  props: {
    fieldsList: {
      type: Array,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
    fieldToMove: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fieldToSelect: null,
    }
  },
  computed: {
    fieldsOptions() {
      return this.fieldsList.filter(item => item.id !== this.fieldToMove.id)
        .map(item => ({
          id: item.id,
          label: item.label,
        }))
    },
  },
  methods: {
    apply() {
      this.$emit('moveItemBefore', this.fieldToSelect)
    },
    resetModal() {
      this.fieldToSelect = null
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
